import { el, mount, setAttr } from '../../lib/redom.es.min.js';
import { VippsHurtigkasseController, IConfirmedOrderResponse } from './vipps-hurtigkasse-controller.js';

const PayWithVippsButtonShape = {
    Pill140: 'pill140',
    Pill210: 'pill210',
    Pill250: 'pill250',
    Rect140: 'rect140',
    Rect210: 'rect210',
    Rect250: 'rect250',
} as const;

export class VippsHurtigkasse extends HTMLElement {
    private _locale: string = null;
    private _startBtn: HTMLElement = null;

    private _controller: VippsHurtigkasseController = new VippsHurtigkasseController();
    private _jQuery: any = (window as any).jQuery;
    private _pubSub: any = (window as any).PubSub;

    constructor() {
        super();

        this._locale = this._getKnownLocale();

        this.attachShadow({ mode: 'open' });
    }

    public connectedCallback(): void {
        this._renderStart();
    }

    public startCheckout(force: boolean = false): void {
        if (!force){
            const onBeforeStartCustomEvent = new CustomEvent("onbeforestart", {
                bubbles: true,
                cancelable: true,
                composed: true
              });
    
            const isOnBeforeCancelled = !this.dispatchEvent(onBeforeStartCustomEvent);
            if (isOnBeforeCancelled){
                return;
            }
        }

        if (this._jQuery && typeof this._jQuery.blockUI == 'function'){
            this._jQuery.blockUI();
        }

        const handleReponse = this._processConfirmedOrderResponse.bind(this);
        this._controller.startExpressCheckout().then(handleReponse);
    }

    private _getKnownLocale(): string {
        switch (this.getAttribute('lang')){
            case '3':
                return 'no';
            default:
                return 'en';
        }
    }

    private _renderStart(): void {
        const stylesheetAsset = el("link", { rel: "stylesheet", href: '/components/checkout/vipps/styles/vipps-hurtigkasse.css' });
        mount(this.shadowRoot, stylesheetAsset);

        this._startBtn = el('button', { type: 'button', onclick: this._onclick.bind(this), class: this._computeButtonClass() });
        mount(this.shadowRoot, this._startBtn);
    }

    private _onclick(evt: MouseEvent): void {
        evt.preventDefault();

        this.startCheckout();
    }

    private _processConfirmedOrderResponse(confirmedOrderResponse: IConfirmedOrderResponse): void {
        const error: string = confirmedOrderResponse == null ? 'Empty response' : confirmedOrderResponse.errorMsg;
        if (error) {
            if (this._jQuery && typeof this._jQuery.unblockUI == 'function'){
                this._jQuery.unblockUI();
            }

            if (this._pubSub && typeof this._pubSub.publish == 'function'){
                this._pubSub.publish('notification.error', error);
            }

            console.error(error);
        } else {
            window.location.href = confirmedOrderResponse.paymentProviderInternalData;
        }
    }

    private _computeButtonClass(): string {
        let btnClasses = ['vipps-hurtigkasse-button', this._getButtonShapeClass()];

        const buttonCustomClass = this.getAttribute('btn-class');
        if (buttonCustomClass){
            btnClasses.push(buttonCustomClass);
        }

        return btnClasses.join(' ');
    }

    private _getButtonShapeClass(): string {
        const configuredBtnShape = this.getAttribute('btn-shape');

        const btnShapeClassNameBase = configuredBtnShape && PayWithVippsButtonShape.hasOwnProperty(configuredBtnShape) ? PayWithVippsButtonShape[configuredBtnShape as keyof typeof PayWithVippsButtonShape] : PayWithVippsButtonShape.Rect210;

        return `${btnShapeClassNameBase}-${this._locale}`;
    }
}

customElements.define('vipps-hurtigkasse', VippsHurtigkasse);